// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lcUyO7vZ{align-items:center;display:flex;flex-direction:column;flex-grow:1;height:100%;justify-content:center;min-height:100px;width:100%}.cpDs2wzx{color:var(--color-inactive);font-size:14px;margin-top:10px}.cls25ps7{color:var(--color-error-darken);height:20px;margin-bottom:-4px;position:relative;top:-3px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"spinner": "lcUyO7vZ",
	"text": "cpDs2wzx",
	"error": "cls25ps7"
};
module.exports = ___CSS_LOADER_EXPORT___;
