// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XteiaFsH{margin:0;padding:0 0 40px}@media(min-width:947.98px){.XteiaFsH{padding:0 0 190px}}@media(min-width:1023.98px){.XteiaFsH{--p:12.5%;padding-left:12.5%;padding-left:var(--p);padding-right:12.5%;padding-right:var(--p)}}.PQhcXgCb{margin:0 0 20px;width:100%}@media(min-width:947.98px){.PQhcXgCb{flex:0 0 60%;margin:0}}._93td9X2n{padding:14px 0}.TPg889lh{font-size:24px;line-height:32px;margin:0;padding:0 0 14px}@media(min-width:947.98px){.TPg889lh{font-size:26px;line-height:1.33;padding:0 0 24px}}._6C5l8aP4{color:var(--gradient-to);cursor:pointer;font-size:18px}._5cspPV8U{font-size:16px;line-height:24px;margin:0;padding:0 14px;text-align:left}@media(min-width:947.98px){._5cspPV8U{font-size:18px;line-height:26px;padding:0 24px}}._5cspPV8U p{margin:0;padding:0 0 14px}.tnDXN7by>div{background:none;padding-left:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "XteiaFsH",
	"translation": "PQhcXgCb",
	"session": "_93td9X2n",
	"sessionTitle": "TPg889lh",
	"sessionButton": "_6C5l8aP4",
	"sessionText": "_5cspPV8U",
	"bar": "tnDXN7by"
};
module.exports = ___CSS_LOADER_EXPORT___;
